@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
/*===== GOOGLE FONTS =====*/

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;

  /*========== Colors ==========*/
  --title-color: #434c5e;
  --text-color: #4c566a;
  --text-color-light: #7b88a1;
  --container-color: #ffffff;
  --container-color-alt: #f7f9fb;
  --body-color: #f2f4f8;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== BASE ==========*/
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 3rem 0;
  margin: 0 0 var(--header-height) 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--body-font);
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  background-color: #f2f4f8;
  background-color: var(--body-color);
  color: #4c566a;
  color: var(--text-color);
}

h1,
h2,
h3,
ul,
p {
  margin: 0;
}

h1,
h2,
h3 {
  color: #434c5e;
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --title-color: #eceff4;
  --text-color: #b5c4db;
  --container-color: #2e3440;
  --container-color-alt: #3b4252;
  --body-color: #242933;
}

/*========== Options button ==========*/
.change-theme {
  position: absolute;
  right: 0;
  top: 2.2rem;
  display: flex;
  color: #4c566a;
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
  
  @media only screen and (max-width: 980px)  {
    display: none;
  }
}

.change-theme:hover {
  color: #434c5e;
  color: var(--title-color);
}

.enable-snow {
  position: absolute;
  top: 2.2rem;
  left: 0;
  font-size: 1.2rem;
  color: #4c566a;
  color: var(--text-color);
  cursor: pointer;
}

.enable-snow:hover {
  color: #434c5e;
  color: var(--title-color);
}

/*========== CSS CLASS ==========*/
.section {
  padding: 1.5rem 0;
}

.social_section {
  padding: 0;
}

.icon_flag {
  width: 20px;
}

.section-title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
  color: #434c5e;
  color: var(--title-color);
  font-weight: 600;
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
  letter-spacing: 0.35rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-bottom: var(--mb-3);
}

.bd-container {
  max-width: 968px;
  width: calc(100% - 3rem);
  margin-left: 1.5rem;
  margin-left: var(--mb-3);
  margin-right: 1.5rem;
  margin-right: var(--mb-3);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.l-header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  z-index: var(--z-fixed);
  background-color: #f2f4f8;
  background-color: var(--body-color);
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.nav {
  height: 3rem;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 968px) {
  .nav__menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    width: 100%;
    padding: 2rem 1.5rem;
    background-color: #f2f4f8;
    background-color: var(--body-color);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 1rem 1rem 0 0;
    z-index: 100;
    z-index: var(--z-fixed);
    transition: 0.3s;
  }
}

.nav__logo,
.nav__toggle {
  color: #434c5e;
  color: var(--title-color);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.nav__toggle {
  font-size: 1.2rem;
  cursor: pointer;
}

.nav__item {
  text-align: center;
}

.nav__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}

.nav__link {
  display: flex;
  flex-direction: column;
  font-size: 0.813rem;
  font-size: var(--smaller-font-size);
  color: #7b88a1;
  color: var(--text-color-light);
  font-weight: 500;
  font-weight: var(--font-medium);
}

.nav__link:hover {
  color: #434c5e;
  color: var(--title-color);
}

.nav__icon {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
  z-index: -1;
}

.show-menu {
  bottom: 3rem;
  bottom: var(--header-height);
}

.active-link {
  color: #434c5e;
  color: var(--title-color);
}

.home {
  position: relative;
}

.home__container {
  grid-gap: 3rem;
  gap: 3rem;
}

.home__data {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  text-align: center;
}

.home__img {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  justify-self: center;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
}

.home__title {
  font-size: 1.5rem;
  font-size: var(--h1-font-size);
}

.home__profession {
  font-size: 0.938rem;
  font-size: var(--normal-font-size);
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
}

.home__address {
  grid-gap: 1rem;
  gap: 1rem;
}

.home__information {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-size: var(--small-font-size);
}

.home__icon {
  font-size: 1.2rem;
  margin-right: 0.25rem;
}

.home__button-movil {
  display: inline-block;
  border: 2px solid #4c566a;
  border: 2px solid var(--text-color);
  color: #434c5e;
  color: var(--title-color);
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  transition: 0.3s;
  font-weight: 500;
  font-weight: var(--font-medium);
  margin-top: 1.5rem;
  margin-top: var(--mb-3);
}

.home__button-movil:hover {
  background-color: #4c566a;
  background-color: var(--text-color);
  color: #ffffff;
  color: var(--container-color);
}

.social {
  padding-top: 0;
}

.social__container {
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  grid-gap: 1rem;
  gap: 1rem;
}

.social__link {
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  font-size: var(--small-font-size);
  color: #4c566a;
  color: var(--text-color);
}

.social__link:hover {
  color: #434c5e;
  color: var(--title-color);
}

.social__icon {
  font-size: 1.2rem;
  margin-right: 0.25rem;
}

.profile__description {
  text-align: center;
}

.education__content,
.experience__content {
  display: flex;
}

.education__time,
.experience__time {
  padding-right: 1rem;
}

.education__rounder,
.experience__rounder {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #7b88a1;
  background-color: var(--text-color-light);
  border-radius: 50%;
  margin-top: 0.25rem;
}

.education__line,
.experience__line {
  display: block;
  width: 2px;
  height: 110%;
  background-color: #7b88a1;
  background-color: var(--text-color-light);
  -webkit-transform: translate(7px, 0);
          transform: translate(7px, 0);
}

.education__data,
.experience__data {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.education__title,
.experience__title {
  font-size: 1rem;
  font-size: var(--h3-font-size);
}

.education__studies,
.experience__company {
  font-style: italic;
  color: #7b88a1;
}

.experience__proyect {
  font-size: 0.875rem;
  font-size: var(--small-font-size);
  color: #434c5e;
  color: var(--title-color);
}

.education__year {
  font-size: 0.813rem;
  font-size: var(--smaller-font-size);
}

.skills__content {
  grid-template-columns: repeat(1, 1fr);
}

ul {
  padding: 5px;
}

.skills_group_title {
  color: #434c5e;
  color: var(--title-color);
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.skills_group_item {
  ul {
    padding: 5px;
  }
}


.skills_data {
  display: flex;
  flex-wrap: wrap;
}

.skills__name {
  flex: 1 1;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
}

.skills__circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #4c566a;
  background-color: var(--text-color);
  border-radius: 50%;
  margin-right: 0.75rem;
}

/*========== React-Particles-JS ==========*/
#tsparticles {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.body-particles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

/*========== MEDIA QUERIES ==========*/

@media screen and (max-width: 320px) {
  .nav__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem 0.5rem;
    gap: 1rem 0.5rem;
  }
}

@media screen and (min-width: 968px) {
  body {
    margin: 3rem 0;
  }

  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }

  .l-header,
  .scrolltop {
    display: none;
  }

  .resume {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    background-color: #ffffff;
    background-color: var(--container-color);
    box-shadow: 0 0 8px rgba(13, 12, 12, 0.15);
  }

  .resume__left {
    background-color: #f7f9fb;
    background-color: var(--container-color-alt);
  }

  .resume__left,
  .resume__right {
    padding: 0 1.5rem;
  }

  .enable-snow {
    display: inline-block;
  }

  .section-title,
  .profile__description {
    text-align: left;
    text-align: initial;
  }

  .home__container {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

